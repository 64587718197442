//
// Footer.scss
//


.bg-footer{
  border-radius: 450px 0px 0px 0px;
  .contact-img{
    position: absolute;
    margin-top: -280px;
  }
}

.footer-link{
  a {
      color: $muted;
      line-height: 38px;
      font-size: 15px;
      transition: all 0.5s;
      &:hover {
          color: $primary;
        }
    }
}


.subscribe {
  input {
    padding: 12px 20px;
    width: 100%;
    font-size: 15px;
    color: $muted;
    border: none;
    outline: none !important;
    padding-right: 75px;
    padding-left: 15px;
    background-color: rgba($primary, 0.1);
    border-radius: 5px;
  }
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    outline: none !important;
    border-radius: 0px 5px 5px 0px;
    padding: 2px;
    font-size: 25px;
    width: 100px;
  }
  form {
    position: relative;
    max-width: 400px;
  }
  ::placeholder { 
    color: $muted
  }
}


